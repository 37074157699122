<template>
<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0 viewport-fit=cover">

  <title>Christmas at Soul</title>
</head>
<body>
  <div id="main">
    <h1>Christmas At Soul</h1>
    <h2>A CHRISTMAS EVE FAMILY WORSHIP GATHERING</h2>
    <h4>DECEMBER 24th, 4PM AT THE FARM AT 42</h4>
    <a href="https://soulchurchnc.churchcenter.com/registrations/events/2599621" class="button">Click Here for Free Tickets</a>
  </div>
</body>
</html>
</template>

<script>
export default {
  name: 'ChristmasComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    @font-face { font-family: LovelyChristmas; src: url('../../public/LovelyChristmas.ttf'); } 

    body {
      background-image: url('../assets/CAS_background.jpg');
      background-color: #dc4042; 
      color: #f4ebd9;
      padding: 1rem;
      max-height: 10rem;
    }

    #main {
      margin-top: 5rem;
      padding: 1rem;
      overflow: auto;
    }

    @media (min-width:650px) {
      #main {
        margin-top: 10rem;
      }
    }
  
    h1 {
      font-family: 'LovelyChristmas';
      font-size: calc(4vw + 4vh);
      font-weight: 300;
      margin: 0;
      display: inline-flex;
      text-transform: uppercase;
      text-align: center;
      background: -webkit-linear-gradient(#ddf6c1, #cad454);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @media (min-width:650px) {
      h1 {
        font-size: calc(6vw + 6vh);
      }
    }
    @media (min-width:470px) {
      h1 {
        font-size: calc(6vw + 6vh);
      }
    }
    @media (min-width:380px) {
      h1 {
        font-size: calc(5vw + 5vh);
      }
    }
    h2 {
      font-size: 2em;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
      text-align: center;

      margin: 10px 0;
    }
    h3 {
      font-size: 1em;
      text-align: center;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
      margin: 10px 0;
    }
    h4 {
      font-size: 1.5em;
      text-align: center;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
      margin: 10px 0;
    }
    .button {
      margin-top: 3rem;
      font-family: 'Times New Roman', Times, serif;
      display: inline-block;
      padding: 15px 30px;
      background-color: transparent;
      color: #f4ebd9;
      border: 2px solid #f4ebd9;
      text-decoration: none;
      font-size: 1.2em;
      text-transform: uppercase;
      cursor: pointer;
    }
    .button:hover {
      background-color: #f4ebd9;
      color: #d72d1f;
    }
</style>
